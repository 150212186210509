import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import * as echarts from "echarts";
import { getSteemMarketHistory } from "../../apis/services/steemActivity";
import { $t } from "../../plugins/i18n";
export default {
  name: "TradingChart",
  data: function data() {
    return {
      chartData: [
        /*  { time: "2025-01-01", open: 100, high: 110, low: 90, close: 75 },
        { time: "2025-01-02", open: 105, high: 115, low: 95, close: 110 },
        { time: "2025-01-03", open: 110, high: 120, low: 100, close: 95 },
        { time: "2025-01-04", open: 115, high: 125, low: 105, close: 120 },
        { time: "2025-01-05", open: 120, high: 100, low: 110, close: 115 }, */
      ]
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.loadChartData();
    setInterval(function () {
      _this.loadChartData();
    }, 5000);
  },
  methods: {
    loadChartData: function loadChartData() {
      var _this2 = this;

      getSteemMarketHistory(0, 5).then(function (data) {
        _this2.chartData = [];

        if (data.code == 0) {
          data.data.items.forEach(function (item) {
            _this2.chartData.unshift({
              time: item.tradeDate.slice(0, 10),
              open: item.open,
              high: item.high,
              low: item.low,
              close: item.close
            });
          });

          _this2.initChart();
        }
      });
    },
    initChart: function initChart() {
      var chartDom = this.$refs.chart;
      var myChart = echarts.init(chartDom);
      var option = {
        title: {
          text: $t("label.current") + $t("label.price") + this.chartData[this.chartData.length - 1].close
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          data: this.chartData.map(function (item) {
            return item.time;
          })
        },
        yAxis: {
          type: "value"
        },
        series: [{
          name: "Steem Prices",
          type: "candlestick",
          data: this.chartData.map(function (item) {
            return [item.open, item.close, item.low, item.high];
          }),
          itemStyle: {
            color: "#ff0000",
            // Red for down
            color0: "#00ff00" // Green for up

          }
        }]
      };
      myChart.setOption(option);
    }
  }
};