import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-19f9bb9c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  "class": "trading-chart-container"
};
var _hoisted_2 = {
  ref: "chart",
  "class": "chart"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, null, 512)]);
}