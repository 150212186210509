import Chart from "chart.js";
export default {
  name: "HistoryTable",
  props: {
    history: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (row.type === "buy") {
        return "buy";
      } else if (row.type === "sell") {
        return "sell";
      }

      return "";
    },
    handleRowClick: function handleRowClick(row, column) {
      this.$bus.emit("choosePrice", row.price);
    }
  }
};