import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-eaf36968"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  "class": "row"
};
var _hoisted_2 = {
  "class": "container-fluid mt-2"
};
var _hoisted_3 = {
  "class": "container-fluid mt-2"
};
var _hoisted_4 = {
  "class": "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_community_easy_card = _resolveComponent("community-easy-card");

  var _component_base_header = _resolveComponent("base-header");

  var _component_el_option = _resolveComponent("el-option");

  var _component_el_select = _resolveComponent("el-select");

  var _component_post_card = _resolveComponent("post-card");

  var _component_vue_loadmore = _resolveComponent("vue-loadmore");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-4 pb-4 pt-5 pt-md-5"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.communities, function (item) {
        return _openBlock(), _createBlock(_component_community_easy_card, {
          key: item,
          communities: item,
          "class": "pb-2 pb-2 pt-1 pt-md-1"
        }, null, 8, ["communities"]);
      }), 128))])];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_select, {
    modelValue: $data.selectPost,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.selectPost = $event;
    })
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectPostOption, function (item) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: item.key,
          label: item.key,
          value: item.value
        }, null, 8, ["label", "value"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_vue_loadmore, {
    "on-refresh": $options.onRefresh,
    "on-loadmore": $options.onLoad,
    finished: _ctx.finished,
    "load-offset": 200
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.blogs, function (item) {
        return _openBlock(), _createBlock(_component_post_card, {
          key: item.id,
          blogs: item,
          "class": "pb-2 pb-2 pt-1 pt-md-1"
        }, null, 8, ["blogs"]);
      }), 128))])];
    }),
    _: 1
  }, 8, ["on-refresh", "on-loadmore", "finished"])])])], 64);
}