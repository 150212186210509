export default {
  name: "TransactionInfo",
  props: {
    transactions: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    buyPrices: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    sellPrices: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  methods: {
    handleRowClick: function handleRowClick(row, column) {
      this.$bus.emit("choosePrice", row.price);
    }
  }
};