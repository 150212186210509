import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "history-table"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h3", null, _toDisplayString(_ctx.$t("label.transactionHistory")), 1), _createVNode(_component_el_table, {
    data: $props.history,
    style: {
      "width": "100%"
    },
    "row-class-name": $options.tableRowClassName,
    onRowClick: $options.handleRowClick
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        prop: "type",
        label: _ctx.$t('label.tradeType'),
        width: "120"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "quantity",
        label: _ctx.$t('label.quantity'),
        width: "120"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "price",
        label: _ctx.$t('label.price'),
        width: "120"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "timestamp",
        label: _ctx.$t('label.timestamp')
      }, null, 8, ["label"])];
    }),
    _: 1
  }, 8, ["data", "row-class-name", "onRowClick"])]);
}