import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3a080066"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  "class": "container-fluid d-flex align-items-center"
};
var _hoisted_2 = {
  "class": "row"
};
var _hoisted_3 = {
  "class": "col-lg-10 col-md-12"
};
var _hoisted_4 = {
  "class": "text-white mt-0 mb-5"
};
var _hoisted_5 = {
  "class": "container-fluid mt--7"
};
var _hoisted_6 = {
  "class": "row justify-content-center"
};
var _hoisted_7 = {
  style: {
    "width": "100%"
  },
  "class": "row"
};
var _hoisted_8 = {
  "class": "col-xl-6"
};
var _hoisted_9 = {
  "class": "col-xl-6"
};
var _hoisted_10 = {
  style: {
    "margin-top": "10px",
    "width": "100%"
  },
  "class": "row"
};
var _hoisted_11 = {
  "class": "col-xl-6"
};
var _hoisted_12 = {
  "class": "col-xl-6"
};
var _hoisted_13 = {
  style: {
    "margin-top": "10px",
    "width": "100%"
  },
  "class": "row"
};
var _hoisted_14 = {
  "class": "col-xl-12"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_HistoryTable = _resolveComponent("HistoryTable");

  var _component_TradingChart = _resolveComponent("TradingChart");

  var _component_TradePanel = _resolveComponent("TradePanel");

  var _component_TransactionInfo = _resolveComponent("TransactionInfo");

  var _component_TradingOrderList = _resolveComponent("TradingOrderList");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pt-2 pt-md-7"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("label.marketIntro")), 1)])])])];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_HistoryTable, {
    history: $data.historyData
  }, null, 8, ["history"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_TradingChart)])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_withDirectives(_createVNode(_component_TradePanel, {
    account: $data.account,
    loginData: $data.loginData
  }, null, 8, ["account", "loginData"]), [[_vShow, $data.isLogin]])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_TransactionInfo, {
    buyPrices: $data.buyPrices,
    sellPrices: $data.sellPrices
  }, null, 8, ["buyPrices", "sellPrices"])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_withDirectives(_createVNode(_component_TradingOrderList, {
    tradingData: $data.tradingData,
    loginData: $data.loginData
  }, null, 8, ["tradingData", "loginData"]), [[_vShow, $data.isLogin]])])])])])]);
}