import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import LuckNum from "../views/LuckNum.vue";
import Airdrop from "../views/Airdrop.vue";
import PointsMarket from "../views/PointsMarket.vue";
import Home from "../views/Home.vue";
import Article from "../views/Article.vue";
import MainLayout from "../layout/MainLayout.vue";
import LuckNumAirDropRank from "../views/LuckNumAirDropRank.vue";
import MarketUVFExchange from "../views/MarketUVFExchange.vue";
import Post from "../views/Post.vue";
import Community from "../views/Community.vue";
import CommunityIndex from "../views/CommunityIndex.vue";
import Friend from "../views/Friend.vue";
import Setting from "../views/Setting.vue";
import Wallet from "../views/Wallet.vue";
import Reg from "../views/Reg.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Program from "../views/Program.vue";
import Sap from "../views/SAP.vue";
import Tools from "../views/Tools.vue";
import AuthorityManagement from "../views/AuthorityManagement.vue";
import MyCommunities from "../views/MyCommunities.vue";
import Market from "../views/Market.vue";

const routes = [
  {
    path: "/",
    redirect: "/community",
    component: DashboardLayout,
    children: [
      {
        path: "/lucknum",
        name: "lucknum",
        components: { default: LuckNum },
      },
      {
        path: "/airdrop",
        name: "airdrop",
        components: { default: Airdrop },
      },
      {
        path: "/pointsmarket",
        name: "pointsmarket",
        components: { default: PointsMarket },
      },
      {
        path: "/market",
        name: "market",
        components: { default: Market },
      },
      {
        path: "/program",
        name: "program",
        components: { default: Program },
      },
      {
        path: "/tools",
        name: "tools",
        components: { default: Tools },
      },
      {
        path: "/authorityManagement",
        name: "authorityManagement",
        components: { default: AuthorityManagement },
      },

      {
        path: "/lucknumairdroprank",
        name: "lucknumairdroprank",
        components: { default: LuckNumAirDropRank },
      },
      {
        path: "/marketuvfexchange/:id",
        name: "marketuvfexchange",
        components: { default: MarketUVFExchange },
      },
      {
        path: "/post",
        name: "post",
        components: { default: Post },
      },
      {
        path: "/post/@:username/:permlink",
        name: "post2",
        components: { default: Post },
      },
      {
        path: "/post/:community",
        name: "post1",
        components: { default: Post },
      },
      {
        path: "/post/:community/@:username/:permlink",
        name: "post2",
        components: { default: Post },
      },
      {
        path: "/sappost/:programId",
        name: "sappost",
        components: { default: Post },
      },
      {
        path: "/sappost/:programId/:id",
        name: "sappost1",
        components: { default: Post },
      },
    ],
  },
  {
    path: "/@:username",
    component: DashboardLayout,

    children: [
      {
        path: "/wallet/@:username",
        name: "wallet",
        components: { default: Wallet },
      },
      {
        path: "/@:username",
        name: "home",
        components: { default: Home },
      },

      {
        path: "/@:username/:permlink",
        name: "article",
        components: { default: Article },
      },
    ],
  },
  {
    path: "/:community/@:username/:permlink",
    component: DashboardLayout,

    children: [
      {
        path: "/:community/@:username/:permlink",
        name: "article1",
        components: { default: Article },
      },
      {
        path: "/community",
        name: "community",
        components: { default: Community },
      },
      {
        path: "/:sort/:community",
        name: "communityIndex",
        components: { default: CommunityIndex },
      },
      {
        path: "/friend",
        name: "friend",
        components: { default: Friend },
      },
      {
        path: "/friend/@:username",
        name: "friend1",
        components: { default: Friend },
      },
      {
        path: "/myCommunities",
        name: "myCommunities",
        components: { default: MyCommunities },
      },
      {
        path: "/myCommunities/@:username",
        name: "myCommunities1",
        components: { default: MyCommunities },
      },
      {
        path: "/setting",
        name: "setting",
        components: { default: Setting },
      },
      {
        path: "/reg",
        name: "reg",
        components: { default: Reg },
      },
      {
        path: "/sap/:id",
        name: "sap",
        components: { default: Sap },
      },
      {
        path: "/verifyemail",
        name: "verifyemail",
        components: { default: VerifyEmail },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
