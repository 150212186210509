import { Client, PrivateKey } from "@boylikegirl/dsteem";
import { extractLoginData } from "./UserUtil";
import { toDecimal } from "./public";
const client = new Client(
  localStorage.getItem("rpcNode")
    ? localStorage.getItem("rpcNode")
    : process.env.VUE_APP_STEEM_RPC.split("|")[0]
);
/**
 * 获取区块全局信息
 * @returns 获取区块全局信息
 */
export const getDynamicGlobalProperties = async () => {
  return new Promise((resolve, reject) => {
    client.database.getDynamicGlobalProperties().then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取账号账号信息
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getAccounts = async (author) => {
  return new Promise((resolve, reject) => {
    client.database.call("get_accounts", [[author]]).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
export const signTx = (op, postingKey) => {
  try {
    const privateKey = PrivateKey.fromString(postingKey);
    return client.broadcast.sign(op, privateKey);
  } catch (ex) {
    return ex;
  }
};

/**
 * 给Blog投票
 * @param {} data
 */
export const voteBlog = (data) => {
  //get private key
  const privateKey = PrivateKey.fromString(data.postingKey);
  let weight = parseInt(data.weight, 10);
  const vote = {
    voter: data.voter,
    author: data.author,
    permlink: data.permlink,
    weight, //needs to be an integer for the vote function
  };

  //broadcast the vote
  client.broadcast.vote(vote, privateKey).then(
    function (result) {
      //console.log(result);
    },
    function (error) {
      //console.log(error);
    }
  );
};

/**
 * 获取文章信息
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getPostDetails = async (author, permlink) => {
  return new Promise((resolve, reject) => {
    client.database.call("get_content", [author, permlink]).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取文章信息
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getPostDetailsByBridge = async (author, permlink) => {
  return new Promise((resolve, reject) => {
    client.customCall("bridge", "get_discussion", { author, permlink }).then(
      function (result) {
        resolve(result[author + "/" + permlink]);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};

/**
 * 发送操作
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const sendOperations = async (op, postingKey) => {
  return new Promise((resolve, reject) => {
    try {
      let loginData = extractLoginData();
      const myAccount = loginData[0];
      if (loginData[4] == "true") {
        window.steem_keychain.requestBroadcast(
          myAccount,
          op,
          "posting",
          function (response) {
            resolve(response.result);
          }
        );
      } else {
        const privateKey = PrivateKey.fromString(
          postingKey ? postingKey : loginData[1]
        );

        client.broadcast.sendOperations(op, privateKey).then(
          function (result) {
            resolve(result);
          },
          function (error) {
            if (error) {
              reject(error);
              return;
            } else {
              reject(500);
            }
          }
        );
      }
    } catch (ex) {
      resolve(ex);
    }
  });
};

/**
 * 获取用户的Blog
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getAccountBlogs = async (author, limit, start_permlink) => {
  return new Promise((resolve, reject) => {
    var params = {
      tag: author,
      limit: limit,
      start_permlink,
      start_author: author,
    };
    client.database.getDiscussions("blog", params).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};

/**
 * 获取账号关注
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getFollowing = async (author, isMute) => {
  return new Promise((resolve, reject) => {
    client.database
      .call("get_following", [author, "", isMute ? "ignore" : "blog", 1000])
      .then(
        function (result) {
          resolve(result);
        },
        function (error) {
          if (error) {
            reject(error);
            return;
          } else {
            reject(500);
          }
        }
      );
  });
};
/**
 * 获取账号关注
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getFollowers = async (author) => {
  return new Promise((resolve, reject) => {
    client.database.call("get_followers", [author, "", "blog", 1000]).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 发送文章
 * @param {*} input
 * @returns 发送文章
 */
export const postBlog = async (input, isEdit, currentPermlink) => {
  return new Promise((resolve, reject) => {
    const permlink = isEdit
      ? currentPermlink
      : Math.random().toString(36).substring(2);
    input.permlink = permlink;
    client.broadcast
      .comment(
        {
          author: input.author,
          body:
            input.body +
            "<br /><br /><br /> <p><em>(Published through Steemit Dapp https://boylikegirl.club)</em></p>",
          json_metadata: input.json_metadata,
          parent_author: input.parent_author || "",
          parent_permlink: input.parent_permlink || input.taglist[0],
          permlink: permlink,
          title: input.title || "",
        },
        PrivateKey.fromString(input.postingKey)
      )
      .then(
        function (result) {
          resolve(result);
        },
        function (error) {
          reject(error);
        }
      );
  });
};

/**
 *
 * @param {*} author
 * @returns 转发
 */
export const postBlogWithOptions = async (
  input,
  isEdit,
  currentPermlink,
  beneficiaries
) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();
    let blogPostRewards = localStorage.getItem("blogPostRewards");
    let commentPostRewards = localStorage.getItem("commentPostRewards");
    const myAccount = loginData[0];

    const permlink = isEdit
      ? currentPermlink
      : Math.random().toString(36).substring(2);

    input.permlink = permlink;

    let options = [];

    const data = [
      "comment",
      {
        parent_author: input.parent_author || "",
        parent_permlink: input.parent_permlink || input.taglist[0],
        author: input.author,
        permlink: permlink,
        title: input.title || "",
        body:
          input.body +
          "<br /><br /><br /> <p><em>(Published through Steemit Dapp https://boylikegirl.club)</em></p>",
        json_metadata: input.json_metadata,
      },
    ];

    let extensions = [];
    beneficiaries.sort((a, b) => {
      if (a.account < b.account) {
        return -1;
      }
      if (a.account > b.account) {
        return 1;
      }
      return 0;
    });
    if (beneficiaries && beneficiaries.length > 1) {
      let beneficiarieList = [
        0,
        {
          beneficiaries: beneficiaries,
        },
      ];
      extensions.push(beneficiarieList);
    }

    const option = [
      "comment_options",
      {
        author: input.author,
        permlink: permlink,
        max_accepted_payout: "1000000.000 SBD",
        percent_steem_dollars:
          blogPostRewards == "1" || commentPostRewards == "1" ? 0 : 10000,
        allow_votes: true,
        allow_curation_rewards: true,
        extensions,
      },
    ];

    options.push(data);

    if (
      (blogPostRewards && blogPostRewards == "1" && !input.parent_permlink) ||
      (beneficiaries && beneficiaries.length > 1 && !input.parent_permlink)
    ) {
      options.push(option);
    }
    if (
      commentPostRewards &&
      commentPostRewards == "1" &&
      input.parent_permlink
    ) {
      options.push(option);
    }

    if (loginData[4] == "true") {
      window.steem_keychain.requestBroadcast(
        myAccount,
        options,
        "posting",
        function (response) {
          resolve(response.result);
        }
      );
    } else {
      const privateKey = PrivateKey.fromString(loginData[1]);

      client.broadcast.sendOperations(options, privateKey).then(
        function (result) {
          resolve(result);
        },
        function (error) {
          reject(error);
        }
      );
    }
  });
};

/**
 * 获取文章留言
 * @param {*} author
 * @returns 获取文章留言
 */
export const getPostReplies = async (author, permlink) => {
  return new Promise((resolve, reject) => {
    client.database.call("get_content_replies", [author, permlink]).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取文章所有状态包括留言
 * @param {*} 地址
 * @returns 获取文章所有状态包括留言
 */
export const getState = async (url) => {
  return new Promise((resolve, reject) => {
    client.database.getState(url).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取账户所有状态包括留言
 * @param {*} 地址
 * @returns 获取文章所有状态包括留言
 */
export const getAccountState = async (url) => {
  return new Promise((resolve, reject) => {
    client.call("database_api", "get_state", [url]).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取中间价
 * @param {*} author
 * @returns 当前中间价
 */
export const getCurrentMedianHistoryPrice = async () => {
  return new Promise((resolve, reject) => {
    client.database.call("get_current_median_history_price", []).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 *
 * @param {*} author
 * @returns 转发
 */
export const resteem = async (author, permlink) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();

    const myAccount = loginData[0];

    const jsonOp = JSON.stringify([
      "reblog",
      {
        account: myAccount,
        author: author,
        permlink: permlink,
      },
    ]);

    const data = {
      id: "follow",
      json: jsonOp,
      required_auths: [],
      required_posting_auths: [myAccount],
    };

    if (loginData[4] == "true") {
      window.steem_keychain.requestBroadcast(
        myAccount,
        [["custom_json", data]],
        "posting",
        function (response) {
          let dataJson = localStorage.getItem("resteem");
          let dataList = [];
          let data = {};
          data.userName = myAccount;
          data.author = author;
          data.permlink = permlink;
          if (dataJson) {
            dataList = JSON.parse(dataJson);
          }
          dataList.push(data);
          localStorage.setItem("resteem", JSON.stringify(dataList));
        }
      );
    } else {
      const privateKey = PrivateKey.fromString(loginData[1]);
      client.broadcast.json(data, privateKey).then(
        function (result) {
          let dataJson = localStorage.getItem("resteem");
          let dataList = [];
          let data = {};
          data.userName = myAccount;
          data.author = author;
          data.permlink = permlink;
          if (dataJson) {
            dataList = JSON.parse(dataJson);
          }
          dataList.push(data);
          localStorage.setItem("resteem", JSON.stringify(dataList));
        },
        function (error) {}
      );
    }
  });
};

/**
 * 获取用户的Blog
 * @param {*} category
 * The type of sorting for the discussions, valid options are:
 * active blog cashout children comments created feed hot promoted trending votes.
 * Note that for blog and feed the tag is set to a username.
 * @returns 获取账号账号信息
 */
export const getBlogs = async (
  category,
  tag,
  limit,
  start_permlink,
  start_author
) => {
  return new Promise((resolve, reject) => {
    var params = {
      tag: tag,
      limit: limit,
      start_permlink,
      start_author,
    };

    client.database.getDiscussions(category, params).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};

/**
 * 获取用户的Blog
 * @param {*} category
 * The type of sorting for the discussions, valid options are:
 * active blog cashout children comments created feed hot promoted trending votes.
 * Note that for blog and feed the tag is set to a username.
 * @returns 获取账号账号信息
 */
export const getCommunities = async (observer, query, sort) => {
  return new Promise((resolve, reject) => {
    var params = {
      query,
      sort,
    };

    if (observer) params.observer = observer;
    client.customCall("bridge", "list_communities", params).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取社区信息
 * @param {*} category
 * @returns 获取账号账号信息
 */
export const getCommunity = async (observer, name) => {
  return new Promise((resolve, reject) => {
    var params = {
      observer: observer ?? "",
      name,
    };

    client.customCall("bridge", "get_community", params).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
export const getBlogsByBridge = async (
  observer,
  sort,
  tag,
  limit,
  start_permlink,
  start_author
) => {
  return new Promise((resolve, reject) => {
    var params = {
      sort: sort ?? "",
      tag: tag ?? "",
      observer: observer ?? "",
      start_permlink: start_permlink ?? "",
      start_author: start_author ?? "",
      limit: limit ?? 20,
    };

    client.customCall("bridge", "get_ranked_posts", params).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};

/**
 * 获取用户的Blog
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getAccountBlogsByBridge = async (
  observer,
  sort,
  account,
  limit,
  start_permlink,
  start_author
) => {
  return new Promise((resolve, reject) => {
    var params = {
      sort: sort ?? "",
      account: account ?? "",
      observer: observer ?? "",
      start_permlink: start_permlink ?? "",
      start_author: start_author ?? "",
    };

    client.customCall("bridge", "get_account_posts", params).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取用户的Blog
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const getAccountSubscriptionsByBridge = async (account) => {
  return new Promise((resolve, reject) => {
    var params = {
      account: account ?? "",
    };

    client.customCall("bridge", "list_all_subscriptions", params).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 *
 * @param {*} author
 * @returns 订阅,
 */
export const subscribeCommunity = async (communityName, isSubscribe) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();

    const myAccount = loginData[0];

    const jsonOp = JSON.stringify([
      isSubscribe ? "subscribe" : "unsubscribe",
      {
        community: communityName,
      },
    ]);

    const data = {
      id: "community",
      json: jsonOp,
      required_auths: [],
      required_posting_auths: [myAccount],
    };

    if (loginData[4] == "true") {
      window.steem_keychain.requestBroadcast(
        myAccount,
        [["custom_json", data]],
        "posting",
        function (response) {
          resolve(response);
        }
      );
    } else {
      const privateKey = PrivateKey.fromString(loginData[1]);

      client.broadcast.json(data, privateKey).then(
        function (result) {
          resolve(result);
        },
        function (error) {}
      );
    }
  });
};

/**
 *
 * @param {*} author
 * @returns 关注,
 */
export const followUser = async (userName, isFollow) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();

    const myAccount = loginData[0];

    const jsonOp = JSON.stringify([
      "follow",
      {
        follower: myAccount,
        following: userName,
        what: [isFollow ? "blog" : "", ""],
      },
    ]);

    const data = {
      id: "follow",
      json: jsonOp,
      required_auths: [],
      required_posting_auths: [myAccount],
    };

    if (loginData[4] == "true") {
      window.steem_keychain.requestBroadcast(
        myAccount,
        [["custom_json", data]],
        "posting",
        function (response) {
          resolve(response);
        }
      );
    } else {
      const privateKey = PrivateKey.fromString(loginData[1]);

      client.broadcast.json(data, privateKey).then(
        function (result) {
          resolve(result);
        },
        function (error) {}
      );
    }
  });
};
/**
 *
 * @param {*} author
 * @returns 屏蔽,
 */
export const muteUser = async (userName, isMute) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();

    const myAccount = loginData[0];

    const jsonOp = JSON.stringify([
      "follow",
      {
        follower: myAccount,
        following: userName,
        what: ["", isMute ? "ignore" : ""],
      },
    ]);

    const data = {
      id: "follow",
      json: jsonOp,
      required_auths: [],
      required_posting_auths: [myAccount],
    };

    if (loginData[4] == "true") {
      window.steem_keychain.requestBroadcast(
        myAccount,
        [["custom_json", data]],
        "posting",
        function (response) {
          resolve(response);
        }
      );
    } else {
      const privateKey = PrivateKey.fromString(loginData[1]);

      client.broadcast.json(data, privateKey).then(
        function (result) {
          resolve(result);
        },
        function (error) {}
      );
    }
  });
};

/**
 *
 * @param {*} author
 * @returns 账户更新,
 */
export const accountUpdate2 = async (profile) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();

    const myAccount = loginData[0];

    let op = [
      [
        "account_update2",
        {
          account: myAccount,
          json_metadata: "",
          extensions: [],
          posting_json_metadata: JSON.stringify({ profile }),
        },
      ],
    ];

    if (loginData[4] == "true") {
      window.steem_keychain.requestBroadcast(
        myAccount,
        op,
        "posting",
        function (response) {
          resolve(response);
        }
      );
    } else {
      const privateKey = PrivateKey.fromString(loginData[1]);
      client.broadcast.sendOperations(op, privateKey).then(
        function (result) {
          resolve(result);
        },
        function (error) {}
      );
    }
  });
};

/**
 *
 * @param {*} author
 * @returns 账户更新,
 */
export const accountAuthorizePost = async (activeKey, newAccount) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();
    if (!activeKey && loginData[4] != "true") {
      resolve({
        id: -1,
        message: "error.activeKeyError",
      });
      return;
    }
    const myAccount = loginData[0];

    client.database.getAccounts([myAccount]).then(
      function (result) {
        let _data = result;
        const postingAuth = _data[0].posting;

        const checkAuth = _data[0].posting.account_auths;
        let arrayindex = -1;
        let checktext = " does not yet have posting permission";
        let isHavePermission = false;
        for (let i = 0, len = checkAuth.length; i < len; i++) {
          if (checkAuth[i][0] == newAccount) {
            arrayindex = i;
            isHavePermission = true;
            break;
          }
        }
        if (isHavePermission) {
          resolve({
            id: 0,
            message: "already has posting permission",
          });
          return;
        }

        postingAuth.account_auths.push([
          newAccount,
          parseInt(postingAuth.weight_threshold),
        ]);

        postingAuth.account_auths = postingAuth.account_auths.sort();
        const accObj = {
          account: myAccount,
          json_metadata: _data[0].json_metadata,
          memo_key: _data[0].memo_key,
          posting: postingAuth,
        };
        let op = [["account_update", accObj]];

        if (loginData[4] == "true") {
          window.steem_keychain.requestBroadcast(
            myAccount,
            op,
            "active",
            function (response) {
              response.code = 0;
              resolve(response);
            }
          );
        } else {
          try {
            const privateKey = PrivateKey.fromString(activeKey);
            client.broadcast.sendOperations(op, privateKey).then(
              function (result) {
                result.code = 0;

                resolve(result);
              },
              function (error) {
                resolve({
                  id: -1,
                  message: error.message,
                });
              }
            );
          } catch (ex) {
            resolve({
              id: -1,
              message: ex.message,
            });
          }
        }
      },
      function (error) {
        reject(error);
      }
    );
  });
};
/**
 *
 * @param {*} author
 * @returns 账户更新,
 */
export const removeAuthorize = async (activeKey, newAccount) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();
    if (!activeKey && loginData[4] != "true") {
      resolve({
        id: -1,
        message: "error.activeKeyError",
      });
      return;
    }
    const myAccount = loginData[0];

    client.database.getAccounts([myAccount]).then(
      function (result) {
        let _data = result;
        const postingAuth = _data[0].posting;

        const checkAuth = _data[0].posting.account_auths;
        let arrayindex = -1;
        let checktext = " does not yet have posting permission";
        let isHavePermission = false;
        postingAuth.account_auths = [];

        for (let i = 0, len = checkAuth.length; i < len; i++) {
          if (checkAuth[i][0] == newAccount) {
            arrayindex = i;
            isHavePermission = true;
            continue;
          }
          postingAuth.account_auths.push([
            checkAuth[i][0],
            parseInt(postingAuth.weight_threshold),
          ]);
        }
        if (!isHavePermission) {
          resolve({
            id: 0,
            message: "success",
          });
          return;
        }

        postingAuth.account_auths = postingAuth.account_auths.sort();
        const accObj = {
          account: myAccount,
          json_metadata: _data[0].json_metadata,
          memo_key: _data[0].memo_key,
          posting: postingAuth,
        };
        let op = [["account_update", accObj]];

        if (loginData[4] == "true") {
          window.steem_keychain.requestBroadcast(
            myAccount,
            op,
            "active",
            function (response) {
              response.code = 0;
              resolve(response);
            }
          );
        } else {
          try {
            const privateKey = PrivateKey.fromString(activeKey);
            client.broadcast.sendOperations(op, privateKey).then(
              function (result) {
                result.code = 0;

                resolve(result);
              },
              function (error) {
                resolve({
                  id: -1,
                  message: error.message,
                });
              }
            );
          } catch (ex) {
            resolve({
              id: -1,
              message: ex.message,
            });
          }
        }
      },
      function (error) {
        reject(error);
      }
    );
  });
};

/**
 *
 * @param {*} communityName
 * @param {*} communityInfo
 * @returns 修改社区信息,
 */
export const saveCommunityInfo = async (communityName, communityInfo) => {
  return new Promise((resolve, reject) => {
    let loginData = extractLoginData();

    const myAccount = loginData[0];

    const jsonOp = JSON.stringify([
      "updateProps",
      {
        community: communityName,
        props: communityInfo,
      },
    ]);

    const data = {
      id: "community",
      json: jsonOp,
      required_auths: [],
      required_posting_auths: [myAccount],
    };

    if (loginData[4] == "true") {
      window.steem_keychain.requestBroadcast(
        myAccount,
        [["custom_json", data]],
        "posting",
        function (response) {
          resolve(response);
        }
      );
    } else {
      const privateKey = PrivateKey.fromString(loginData[1]);
      client.broadcast.json(data, privateKey).then(
        function (result) {
          resolve(result);
        },
        function (error) {}
      );
    }
  });
};

/**
 *  交易操作
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const transfer = async (op, activeKey) => {
  return new Promise((resolve, reject) => {
    try {
      const privateKey = PrivateKey.fromString(activeKey);

      client.broadcast.transfer(op, privateKey).then(
        function (result) {
          resolve(result);
        },
        function (error) {
          if (error) {
            reject(error);
            return;
          } else {
            reject(500);
          }
        }
      );
    } catch (ex) {
      resolve(ex);
    }
  });
};

/**
 *  交易操作
 * @param {*} author
 * @returns 获取账号账号信息
 */
export const powerDown = async (name, powerDownAmount, acitveKey) => {
  const _account = await getAccounts(name);
  const avail =
    parseFloat(_account[0].vesting_shares) -
    (parseFloat(_account[0].to_withdraw) - parseFloat(_account[0].withdrawn)) /
      1e6 -
    parseFloat(_account[0].delegated_vesting_shares);

  const props = await getDynamicGlobalProperties();
  const vestSteem =
    parseFloat(props.total_vesting_fund_steem) *
    (parseFloat(avail) / parseFloat(props.total_vesting_shares));
  let vesting_shares =
    toDecimal((avail / vestSteem) * powerDownAmount, 6) + " VESTS";

  let op = [
    [
      "withdraw_vesting",
      {
        account: name,
        vesting_shares: vesting_shares,
      },
    ],
  ];

  let result = await sendOperations(op, acitveKey);

  if (result) {
    return true;
  }
};

/**
 * 获取成交历史
 */
export const getMarketHistoryApi = async (start, end) => {
  return new Promise((resolve, reject) => {
    let para = [];
    if (start && end) {
      para = [start, end, 25];
    } else {
      para = [25];
    }
    client.call("market_history_api", "get_recent_trades", para).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};

/**
 * 获取交易单
 */
export const getOrderBook = async () => {
  return new Promise((resolve, reject) => {
    client.database.call("get_order_book", ["25"]).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};
/**
 * 获取交易中的订单
 */
export const getOpenOrders = async (author) => {
  return new Promise((resolve, reject) => {
    client.database.call("get_open_orders", [author]).then(
      function (result) {
        resolve(result);
      },
      function (error) {
        if (error) {
          reject(error);
          return;
        } else {
          reject(500);
        }
      }
    );
  });
};

export default {
  getDynamicGlobalProperties,
  voteBlog,
  signTx,
  getAccounts,
  getPostDetails,
  sendOperations,
  getAccountBlogs,
  getFollowing,
  getFollowers,
  postBlog,
  postBlogWithOptions,
  getPostReplies,
  getState,
  getCurrentMedianHistoryPrice,
  resteem,
  getBlogs,
  getCommunities,
  subscribeCommunity,
  getBlogsByBridge,
  getPostDetailsByBridge,
  getCommunity,
  saveCommunityInfo,
  accountUpdate2,
  getAccountState,
  followUser,
  muteUser,
  transfer,
  powerDown,
  getAccountBlogsByBridge,
  accountAuthorizePost,
  removeAuthorize,
  getAccountSubscriptionsByBridge,
  getMarketHistoryApi,
  getOrderBook,
  getOpenOrders,
};
