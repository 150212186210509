import { datePickTypes } from "element-plus";
import { get, post, put } from "../axios";
import { extractLoginData } from "../../../src/unit/UserUtil";
// ============================================ commen ============================================
export const getDashboardStatistics = async () =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetDashboardStatistics"
  );

export const login = async (loginData) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL + "/BoylikegirlClubFunction/Login",
    loginData
  );

export const heartBeat = async () => {
  const loginData = extractLoginData();
  if (loginData) {
    let userData = {
      userName: loginData[0],
    };
    if (userData.userName)
      post(
        process.env.VUE_APP_BACKEND_API_URL +
          "/BoylikegirlClubFunction/HeartBeat",
        userData
      );
  }
};

export const getLuckyNumActive = async () =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetLuckyNumActive"
  );

export const getSteemActivePrizeRank = async () =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/getSteemActivePrizeRank"
  );

export const getSbiBalance = async (author) =>
  get("https://api.steembasicincome.com/getUserInfo?user=" + author);

export const getSteemLuckNumUserAirDrop = async (
  userName,
  SteemLuckNumAirDropId
) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/getSteemLuckNumUserAirDrop?userName=" +
      userName +
      "&SteemLuckNumAirDropId=" +
      SteemLuckNumAirDropId
  );
export const getAllLuckNumAirDrop = async () =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetAllLuckNumAirDrop"
  );

export const claimLuckyNumAirDrop = async (airDropClaimData) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/claimLuckyNumAirDrop",
    airDropClaimData
  );
export const getAllSteemLuckNumUserAirDropList = async (
  SteemLuckNumAirDropId
) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetAllSteemLuckNumUserAirDropList?SteemLuckNumAirDropId=" +
      SteemLuckNumAirDropId
  );
export const getAllBoylikegirlClubOperTypeList = async () =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetAllBoylikegirlClubOperTypeList"
  );
export const getAllBoylikegirlClubPointList = async (useranme) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/getAllBoylikegirlClubPointList?username=" +
      useranme
  );
export const getAllBoylikegirlClubTotalPoints = async (useranme) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/getAllBoylikegirlClubTotalPoints?username=" +
      useranme
  );

export const getAllBoylikegirlClubMarkets = async () =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetAllBoylikegirlClubMarkets"
  );
export const getBoylikegirlClubMarketsForExchangeUVF = async (id) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetBoylikegirlClubMarketsForExchangeUVF?id=" +
      id
  );
export const exchangeUVF = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/exchangeUVF",
    data
  );
export const getUVFBalance = async (useranme) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetUVFBalance?username=" +
      useranme
  );

export const existsEmailOrUserName = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/ExistsEmailOrUserName",
    data
  );

export const sendVerifyEmail = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/SendVerifyEmail",
    data
  );
export const verifyEmail = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/VerifyEmail",
    data
  );
export const getAllBoylikegirlClubPrograms = async () =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetAllBoylikegirlClubPrograms"
  );
export const getBoylikegirlClubProgram = async (id) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetBoylikegirlClubProgram?id=" +
      id
  );
export const getBoylikegirlClubSap = async (author, currentPage, pageSize) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetBoylikegirlClubSap?author=" +
      author +
      "&CurrentPage=" +
      currentPage +
      "&MaxResultCount=" +
      pageSize
  );
export const sapEdit = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL + "/BoylikegirlClubFunction/sapEdit",
    data
  );
export const sapDelete = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL + "/BoylikegirlClubFunction/sapDelete",
    data
  );
export const isJoinAutoClaimReward = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/isJoinAutoClaimReward",
    data
  );
export const joinAutoClaimReward = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/joinAutoClaimReward",
    data
  );
export const joinToolData = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/joinToolData",
    data
  );
export const joinAutoFollowClubVote = async (data) =>
  post(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/joinAutoFollowClubVote",
    data
  );
export const getSteemMarketHistory = async (currentPage, pageSize) =>
  get(
    process.env.VUE_APP_BACKEND_API_URL +
      "/BoylikegirlClubFunction/GetSteemMarketHistory?Sorting=id desc&CurrentPage=" +
      currentPage +
      "&MaxResultCount=" +
      pageSize
  );
